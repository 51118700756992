import React from "react";

import tw from "twin.macro";

import { Link } from "gatsby";
import Layout from "../components/util/Layout";
import SEO from "../components/util/SEO";
import NavLayout from "../components/layout/nav-layout";

const FourOhFourPage = () => {
	return (
		<Layout>
			<SEO />
			<NavLayout>
				<div tw="flex flex-col items-center justify-center bg-light-beige font-display text-bitters min-h-screen px-4">
					<h1 tw="font-medium text-5xl">Whoops! </h1>
					<h3 tw=" text-2xl">That page does not exist.</h3>
					<Link
						css={[
							tw`mb-4 mt-6 py-2 bg-bourbon text-white flex items-center justify-center w-full rounded-lg uppercase font-medium lg:w-64`,
						]}
						to="/"
					>
						Back to Home
					</Link>
				</div>
			</NavLayout>
		</Layout>
	);
};

export default FourOhFourPage;
